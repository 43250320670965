import { Component, Prop, Vue } from 'vue-property-decorator'
import Field from '@/shared/classes/components/form/field'
import FormBase from '@/shared/classes/components/form/form-base'
import { IFieldError } from '@/shared/interfaces/form/form.interface'
import _ from 'lodash'
import ArrayField from '@/shared/classes/components/form/fields/array-field'

@Component
export default class AbstractField extends Vue {
  @Prop() field!: Field | any
  @Prop() fullKey!: string
  @Prop() form!: FormBase
  @Prop() group!: ArrayField
  @Prop() rowIndex!: number
  @Prop() size!: string

  get value() {
    if (this.form && !this.form.withoutData) {
      if (!this.field.disabled) this.field.value = _.get(this.form.data, this.fullKey)
      return this.field.value
    }

    return this.field.value
  }

  set value(value) {
    if (this.form && !this.form.withoutData) {
      this.field.value = value
      _.set(this.form.data, this.fullKey, value)

      return
    }

    this.field.value = value
  }

  get actualKey() {
    return this.fullKey ? this.fullKey : this.field.key
  }

  getError(): IFieldError | boolean | null {
    if (!this.form) return null
    return _.get(this.form.errors, this.actualKey, {
      has: false,
      count: 0,
      messages: []
    })
  }

  getErrorState(): boolean | null {
    if (this.getError()) {
      // @ts-ignore
      return !this.getError()?.has ? null : false
    }
    return null
  }

  removeError(): void {
    if (!this.form) return

    delete this.form.errors[this.field.key]
  }
}
